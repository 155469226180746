module.exports = {
  /** Site MetaData (Required all)*/
  title: `Brijesh Shah`,                           // (* Required)
  description: `Brijesh's tech blog. It mainly deals with system design and integration of different technologies.`, // (* Required)
  author: `Brijesh Shah`,                         // (* Required)
  language: 'en-US',                            // (* Required) html lang, ex. 'en' | 'en-US' | 'ko' | 'ko-KR' | ...
  siteUrl: 'https://tuls.in',       // (* Required) 
    // ex.'https://junhobaik.github.io'
    // ex.'https://junhobaik.github.io/' << X, Do not enter "/" at the end.

  /** Header */
  profileImageFileName: 'profile.png', // include filename extension ex.'profile.jpg'
    // The Profile image file is located at path "./images/"
    // If the file does not exist, it is replaced by a random image.

  /** Home > Bio information*/
  comment: 'Full Stack Developer',
  name: 'Brijesh Shah',
  company: 'Paytm Insider',
  location: 'Mumbai, India',
  email: 'brijeshjain13@gmail.com',
  website: '',           // ex.'https://junhobaik.github.io'
  linkedin: 'https://www.linkedin.com/in/brijeshshah13',  // ex.'https://www.linkedin.com/in/junho-baik-16073a19ab'
  facebook: '',                                     // ex.'https://www.facebook.com/zuck' or 'https://www.facebook.com/profile.php?id=000000000000000'
  instagram: '', // ex.'https://www.instagram.com/junhobaik'
  github: 'https://github.com/brijeshshah13',           // ex.'https://github.com/junhobaik'

  /** Post */
  enablePostOfContents: true,     // TableOfContents activation (Type of Value: Boolean. Not String)
  disqusShortname: 'tuls',   // comments (Disqus sort-name)
  enableSocialShare: true,        // Social share icon activation (Type of Value: Boolean. Not String)

  /** Optional */
  googleAnalytics: 'UA-105970366-2',                                  // Google Analytics TrackingID. ex.'UA-123456789-0'
  googleSearchConsole: '', // content value in HTML tag of google search console ownership verification 
  googleAdsenseSlot: '',                                    // Google Adsense Slot. ex.'5214956675'
  googleAdsenseClient: '',                     // Google Adsense Client. ex.'ca-pub-5001380215831339'
    // Please correct the adsense client number(ex.5001380215831339) in the './static/ads.txt' file.
};
